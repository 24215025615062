// Matches the bulma break points.
// https://bulma.io/documentation/overview/responsiveness/#breakpoints

export default {
    mobile: 0,
    portrait: 768,
    tablet: 1024,
    widescreen: 1216,
    fullhd: 1408,
};

export const numberOfProjects = {
    fullHd: {
        top: {
            max: 4,
            min: 2,
        },
        middle: {
            max: 1,
            min: 1,
        },
        bottom: {
            max: 4,
            min: 1,
        },
    },
};
