// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-js": () => import("./../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-our-studio-page-js": () => import("./../src/templates/our-studio-page.js" /* webpackChunkName: "component---src-templates-our-studio-page-js" */),
  "component---src-templates-project-page-js": () => import("./../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-commercial-page-js": () => import("./../src/templates/commercial-page.js" /* webpackChunkName: "component---src-templates-commercial-page-js" */),
  "component---src-templates-community-page-js": () => import("./../src/templates/community-page.js" /* webpackChunkName: "component---src-templates-community-page-js" */),
  "component---src-templates-feasibility-page-js": () => import("./../src/templates/feasibility-page.js" /* webpackChunkName: "component---src-templates-feasibility-page-js" */),
  "component---src-templates-domestic-page-js": () => import("./../src/templates/domestic-page.js" /* webpackChunkName: "component---src-templates-domestic-page-js" */),
  "component---src-templates-renewal-page-js": () => import("./../src/templates/renewal-page.js" /* webpackChunkName: "component---src-templates-renewal-page-js" */),
  "component---src-templates-residential-page-js": () => import("./../src/templates/residential-page.js" /* webpackChunkName: "component---src-templates-residential-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

