import { createMedia } from '@artsy/fresnel';
import breakpoints from '../helpers/breakPoints';

const ExampleAppMedia = createMedia({
    breakpoints,
});

// Make styles for injection into the header of the page
export const mediaStyles = ExampleAppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = ExampleAppMedia;
