module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Grant Murray","short_name":"GrantMurray","icon":"src/img/grant-murray-logo.svg","description":"Formed in 1985, Grant Murray Architects is an established architectural practice in the heart of Glasgow offering award winning designs, an honest, friendly service & efficient delivery.","start_url":"/","background_color":"#2837d6","theme_color":"#2837d6","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
